export default {
  blogerName: 'BESSADA',
  socialsList: [],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/cc78069fe',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 400FS</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c651626b3',
      gameTitle: 'The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c92037035',
      gameTitle: 'Savage Buffalo Spirit Megaways',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c360aca54',
      gameTitle: 'Royal High-Road',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c77b595fb',
      gameTitle: ' All Lucky Clover 5',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c3d2f222f',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/c4787e472',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/cdb92dc1d',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cdb25d0b5',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c4b760e04',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'fresh',
      url: 'https://fresh-azhqmrpiks.com/ca0224f88',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c3a6caebe',
      gameTitle: 'Mechanical Clover',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>BESSADA</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'BESSADA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
